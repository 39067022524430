// Global Contact Details


// Emails
export const general = 'info@hts.com.ng';
export const recruitment = 'recruitment@hts.com.ng';
export const sales = 'sales@hts.com.ng';

// Contact Number
export const telephone = '+234 01 4533418';

// Office Names
export const office1 = 'Head Office';
export const office2 = 'Secondary Office';
export const office3 = 'Tertiary Office';





// ./src/utils/contact.jsx