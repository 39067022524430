import React from "react";
import BacktoTop from "./backtotop";
import SocialLinks from "../social/social";
import Copyright from "./copyright";
import ContactInfo from "./contactinfo";
import Address from "./address";
import { FormattedMessage } from "react-intl";

const ukTag = "";
const polishTag = "";

const Footer = () => (
  <footer className="footer bg-dark pad mpad">
    {/* Top */}
    <div className="footer-top">
      <div className="row expanded">
        {/* Address */}
        <div className="small-12 medium-push-1 medium-4 large-push-1 large-2 columns print-footer__address">
          <div className="footer-top__item">
            <em className="noline">
              <FormattedMessage id="contact" />
            </em>
            <Address
              firstline="5C Apostle Ayo Babalola"
              city="Magodo Shangisha"
              country="Lagos, NG"
            />
            <ContactInfo
              email="info@hts.com.ng"
              telephone="+234 01 4533418"
            />
          </div>
        </div>

        {/* Legal */}
        <div className="small-12 medium-pull-1 medium-10 large-pull-3 large-3 columns print-footer__reg">
          <div className="footer-top__item">
            <p className="not-as-important">
              <FormattedMessage
                id="high_tech_synergy_is_regulated_and_licensed_para"
                values={{
                  CompanyRegNum: (
                    <a target="_blank" href="#">
                      Coporate Affairs Commission
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* Bottom */}
    <div className="footer-bot">
      <div className="row expanded">
        {/* Copyright */}
        <div className="small-12 medium-push-1 medium-10 large-push-1 large-2 columns print-footer__copy">
          <div className="footer-bot__item">
            <Copyright />
          </div>
        </div>

        {/* Links */}
        <div className="small-12 medium-push-1 medium-10 large-push-1 large-6 columns print-footer__legal">
          <div className="footer-bot__item">
            {/* <Legal /> */}
          </div>
        </div>

        {/* Social & Back to Top */}
        <div className="small-12 medium-pull-1 medium-10 large-pull-1 large-2 columns print-footer__social">
          <div className="footer-bot__item last-item">
            {/* Social */}
            <div className="footer-bot__item--social">
              <SocialLinks />
            </div>

            {/* Back to Top */}
            <BacktoTop />
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;




