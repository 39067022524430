import React from 'react'
import SmallIcon from '../global/icons/small-icon'
import SocialLinks from '../global/social/social'
import { FormattedMessage } from 'react-intl'

class ContactDetails extends React.Component {
    constructor(props) {
        super(props);
    }
    

    render() {
        return (
            <div className="contact-details">
                {/* Main Content */}
                <div className="row expanded collapse">
                    <div className="small-12 large-push-2 large-8 columns">
                        <div className="contact-details--wrap">
                            <div className="contact-details__content pad mpad">
                                {/* Heading */}
                                <div className="row expanded">
                                    <div className="small-12 columns">
                                        <div className="contact-details__content--heading">
                                            <em><FormattedMessage id="contact_us" /></em>
                                            <h2 className="dark heading"><FormattedMessage id="get_in_touch" /></h2>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Details */}
                                <div className="row expanded">
                                    {/* Telephone & Find Us */}
                                    <div className="small-12 large-6 columns">
                                        {/* Telephone */}
                                        <div className="contact-details__content--item">
                                            <em className="noline dark"><FormattedMessage id="telephone" /></em>
                                            <a href={`tel:${this.props.telephone.replace(/(\+44|[\(\)]|\s)/g, '')}`} className="telephone">{this.props.telephone}</a>
                                            <a href={`mailto:${this.props.mailSales}`} className="sales">{this.props.mailSales}</a>
                                        </div>

                                        {/* Map Triggers */}
                                        <div className="contact-details__content--item show-for-large">
                                            <em className="noline dark"><FormattedMessage id="find_us" /></em>
                                            <button className="pin" onClick={() => { this.props.toggleMaps(); }}>
                                                <SmallIcon icon="pin" />
                                                <span><FormattedMessage id="our_office" /></span>
                                            </button>
                                        </div>
                                    </div>

                                    {/* Email */}
                                    <div className="small-12 large-6 columns">
                                        <div className="contact-details__content--item nomar">
                                            <em className="noline dark"><FormattedMessage id="email" /></em>
                                            <a href={`mailto:${this.props.mailGeneral}`} className="button dark"><span></span><FormattedMessage id="general" tagName="div"/></a>
                                            <a href={`mailto:${this.props.mailRecruitment}`} className="button dark"><span></span><FormattedMessage id="recruitment" tagName="div" /></a>
                                            <a href={`mailto:${this.props.mailSales}`} className="button dark"><span></span><FormattedMessage id="sales" tagName="div"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Social */}
                <div className="contact-details__social show-for-large">
                    <SocialLinks />

                    <div className="label">
                        <em className="grey"><FormattedMessage id="get_social" /></em>
                    </div>
                </div>

                {/* Close Modal */}
                <div
                    className="contact-details__close"
                    onClick={() => {
                        this.props.closeContact();
                        this.props.closeMaps();
                        this.props.closeChat();
                    }}
                >
                    <button>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        );
    }
}

export default ContactDetails



