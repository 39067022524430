import React from 'react'
import Classnames from 'classnames'
import Icon from '../icons/standard-icon'
import Helmet from 'react-helmet'
import _ from 'lodash';
import { FormattedMessage } from 'react-intl'
import { Link } from '../../../i18n' 

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    let location = null;
    if (typeof document !== 'undefined') {
      const locArr = window.location.pathname.split('/');
      location = locArr[(locArr.length - 1)];
    }
    this.state = {
      open: false,
      productNavOpen: false,
      activePage: location
    };
  }

  isSmallerThan = (pix) => {
    if (typeof document !== 'undefined') {
        return document.documentElement.clientWidth < pix;
    }
    return false;
  }

  getProductsNav() {
    const productsNav = [{
      link: 'accounting',
      label: 'Accounting',
      string:  'accounting'
    }, 
    {
      link: 'ecommerce',
      label: 'ECommerce',
      string: 'ecommerce'
    }];

    return (
      <ul>
        {this.isSmallerThan(1200) &&
        <li>
          <Link to="/solutions/">
            <em className="noline light">
              <FormattedMessage id="solutions"/>
            </em>
          </Link>
        </li>
        }
        {productsNav.map(link => {
            const linkk = `/solutions/${link.link}`;

            return (
              <li className={Classnames({ 'active': this.state.activePage === link.link })} key={link.link}>
                <Link to={linkk}>
                  <em className="noline light">
                    <FormattedMessage id={link.string} />
                  </em>
                </Link>
              </li>
            );
          })
        }
      </ul>
    );
  }

  showProductNav() {

    let state = this.state;
    state.productNavOpen = true;
    this.setState(state);
  }

  hideProductNav() {

    let state = this.state;
    state.productNavOpen = false;
    this.setState(state);
  }


  onToggleClick() {
    this.setState({
      open: !this.state.open
    })
  }


  lockTheBody() {
    document.body.classList.toggle('noscroll');
  }

  closeNav() {
    this.setState({
      open: false
    })
  }


  render() {
    const location = (typeof document !== 'undefined') ? _.compact(window.location.pathname.split('/')) : ['home'];
    const locationName = location.length == 1 ? 'Home' : (location[(location.length - 1)] || 'Home');
    const pageTitle = `High Tech Synergy :: Business Solutions`
    const description = `manage suppliers, customers, inventory, expenses, cash flow and staff payroll amongst other business activities.`

    return (
      <div>
        <Helmet title={pageTitle} meta={[
            { name: 'description', content: description },
            { name: 'keywords', content: 'High Tech Synergy, business solutions, manage suppliers, customers, inventory, expenses, cash flow and staff payroll amongst other business activities.' },
        ]}/>

        {/* Toggle */}
        <button
          className={Classnames("header-toggle no-print", { 
                'open': this.state.open, 
                'isHome': (locationName === 'Home')
          })}
          onClick={() => {
            this.onToggleClick();
            this.lockTheBody();
          }}
        >
          <span></span>
          <span></span>
        </button>

        {/* Navigation */}
        <nav className={Classnames("header-nav", { 'open': this.state.open })}>
          <ul>
            <li className="hide-for-xlarge">
              <Link to="/">
                <span className="dark noline" data-text="Home">Home</span>
              </Link>
            </li>

            <li>
              <Link to="/solutions/" onMouseEnter={this.showProductNav.bind(this)} onMouseLeave={this.hideProductNav.bind(this)}>
                <span className="dark noline" data-text="Solutions"><FormattedMessage id="solutions__"/><Icon icon="down" /></span>
              </Link>
              <div className={Classnames("header-products__menu", { 'open': this.state.productNavOpen })} onMouseEnter={this.showProductNav.bind(this)} onMouseLeave={this.hideProductNav.bind(this)}>
                {this.getProductsNav()}
              </div>
            </li>

            <li>
              <Link to="/about/">
                <span className="dark noline" data-text="About"><FormattedMessage id="about"/></span>
              </Link>
            </li>

            {/* <li>
              <Link to="/careers/">
                <span className="dark noline" data-text="Careers"><FormattedMessage id="careers"/></span>
              </Link>
            </li> */}

            {/* <li>
              <Link to="/news/">
                <span className="dark noline" data-text="News"><FormattedMessage id="News"/></span>
              </Link>
            </li> */}

            <li>
              <Link to="/case-studies/">
                <span className="dark noline" data-text="Case Studies"><FormattedMessage id="case_studies"/></span>
              </Link>
            </li>
            <li>
              <a className="contact-toggle" onClick={() => { this.props.toggleContact(); this.closeNav() }}>
                <span className="dark noline" data-text="Contact"><FormattedMessage id="contact"/></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}

export default Navigation;




