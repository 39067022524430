import React from 'react'
import Classnames from 'classnames'
import axios from 'axios'
import network from '../../utils/network';
import{ init } from 'emailjs-com';

// Images
import robotBG from '../../images/robot.png'
import userBG from '../../images/user.png'
const helperScripts = require('../../utils/helper-scripts');

init(process.env.EMAILJS_USER_ID);


class ContactChat extends React.Component {
    constructor(props) {
        super(props);
        this.cf = null; // <-- Conversational Form ref
    }
    
    
    componentDidMount(){
        // customize your questions here
        this.refs.name.setAttribute('cf-questions', "Hello! What's your name?");
        this.refs.type.setAttribute('cf-questions', "What type of enquiry are you after?");
        this.refs.description.setAttribute('cf-questions', "How can we help?");
        this.refs.email.setAttribute('cf-questions', "What is your email?");
       // submittedResponseText


        this.cf = helperScripts.conversationalForm.startTheConversation({
            formEl: this.refs.form,
            robotImage: robotBG,
            userImage: userBG,
            loadExternalStyleSheet: true,
            context: document.getElementById("cf-context"), // <-- bind this to an element instead of html body
            flowStepCallback: (dto, success, error) => {
                success();
            },
            submitCallback: () => {
                // action when form submitted

                this.submitApplication();
            }
        });
    }


     /*
        Submits form to server
    */
   submitApplication() {
       const {refs} = this;
       const data = {
           name:refs.name.value,
           email: refs.email.value,
           message:refs.description.value,
           enquiryType: refs.type.value
       };

       const postData =  JSON.stringify({
        "user_id":process.env.EMAILJS_USER_ID,
        "service_id": "email_enquiry_service",
        "template_id": "enquiry_contact",
        "template_params":data
       })

        axios.post("https://api.emailjs.com/api/v1.0/email/send", postData, {
            headers: { 'content-type': 'application/json' },
        })
        .then(response => {
                const success  = response.data;
                if(success == "OK") {
                    this.cf.addRobotChatResponse("Thanks, we received your submission and will be in touch shortly.");
                } else {
                    this.cf.addRobotChatResponse("Sorry, there was a problem saving your enquiry. Please email us instead.");
                }
            
        })
        .catch(error => console.log('error: ', error));
    }


    render() {
        return (
            <div className={Classnames("contact-chat", { ['open']: this.props.chatOpen })}> 
                <div id="cf-context" > {/* <-- the cf form will be bound to this element */}
                    <form id="form" className="form" ref="form">
                        <input type="text" ref="name" placeholder="Name" defaultValue={this.props.name}/>

                        <select ref="type" type="radio" id="links"> {/* <-- using selection for options */}
                            <option value="general">General Enquiry</option>
                            <option value="vacancy">Vacancies</option>
                        </select>

                        <input type="text" ref="description" placeholder="Message" defaultValue={this.props.description}/>

                        <input type="email" ref="email" placeholder="Email" defaultValue={this.props.email}/>

                    </form>
                </div>
            </div>
        );
    }
}

// ContactChat.defaultProps = {
//     name: "Input name..",
//     email: "Input email..",
//     description: "Input description..",
// }
    
export default ContactChat;



