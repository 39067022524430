

import React from 'react'
import markerSVG from '../../images/marker.png'
import Classnames from 'classnames'
const helperScripts = require('../../utils/helper-scripts');

class ContactMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapBoxMap = null;
  }

  updateRef(ref) {
    this.mapBoxMap = ref;
  }

  bindMapBox() {

    const { clientWidth } = document.documentElement;

    let zoomLevel = (clientWidth < 1300) ? 3 : 4
    zoomLevel = (clientWidth < 640) ? 2 : zoomLevel;



    const mapbox = new helperScripts.mapboxgl.Map({
      container: this.mapBoxMap,
      style: 'mapbox://styles/mapbox/streets-v9',
      zoom: zoomLevel,
      center: [25,8.675277]
    });

    mapbox.on('load', function () {
      var geojson = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'Point',
            "coordinates": [9.081999,8.675277]
          },
          properties: {
            title: 'Head Office',
            description: '5C Ayo Babalola Street. Magodo Shangisha, <br/>Lagos Nigeria.'
          }
        },
       ]
      };

      // add markers to map
      geojson.features.forEach(function (marker) {

        // create a HTML element for each feature
        var el = document.createElement('div');
        el.className = 'marker';

        new helperScripts.mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(new helperScripts.mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML('<h5>' + marker.properties.title + '</h5><p>' + marker.properties.description + '</p>'))
          .addTo(mapbox);
      });

      mapbox.scrollZoom.disable();
    });


  }

  componentDidMount() {
    this.bindMapBox();
  }

  render() {
    const {
      mapHeight,
      mapsOpen,
      mapWidth,
    } = this.props

    return (

      <div className={Classnames("contact-map--wrap", { ['open']: mapsOpen })}>
        <div
          id="contact-map"
          ref={(ref) => this.updateRef(ref)}
          style={{ width: mapWidth, height: mapHeight }}
        />
      </div>
    );
  }
}

export default ContactMap




