import React from 'react';
import Logo from './logo';
import Navigation from './navigation';
import Language from './language';
import Headroom from 'react-headroom';
import Contact from '../../contact/contact';

// import "../../../css/app.css"

export const Header = ({ action, headerStyle, contactOpen, pathContext }) => (
  <header className={headerStyle}>
    <Headroom>
      <Logo pathContext={pathContext} />
      <Navigation toggleContact={action} />
      <Language pathContext={pathContext} />
    </Headroom>
    <Contact isOpen={contactOpen} toggleContact={action} />
  </header>
);

export default Header




