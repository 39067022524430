/* eslint-disable global-require */

const localeData = [
  ...require('react-intl/locale-data/en'),
  ...require('react-intl/locale-data/pl')
]

module.exports = {
  localeData,
  languages: [
    { value: 'en', text: 'English' },
    { value: 'pl', text: 'Polish' },
  ],
}




// ./src/i18n/locales/index.js