import React from 'react'
import Icon from '../icons/standard-icon'
import {facebook,twitter,linkedin} from '../../../utils/social'

const SocialLinks = () => (
    <ul>
        <li>
            <a href={facebook} className="facebook" target="_blank">
                <Icon icon="facebook"/>
            </a>
        </li>

        <li>
            <a href={linkedin} className="linkedin" target="_blank">
                <Icon icon="linkedin"/>
            </a>
        </li>

        <li>
            <a href={twitter} className="twitter" target="_blank">
                <Icon icon="twitter"/>
            </a>
        </li>
    </ul>
);

export default SocialLinks



