import React from 'react'
import Classnames from 'classnames'
import Details from './details'
import Map from './map'
import Chat from './chat'
import { general, recruitment, sales, telephone, office1, office2 } from '../../utils/contact'



class ContactModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
      chatOpen: true,
      mapsOpen: false,
      isOpen: false,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      isOpen: props.isOpen
    });
  }


  closeContact = () => {
    this.props.toggleContact();
  }

  toggleMaps = () => {
    this.setState({
      mapsOpen: !this.state.mapsOpen,
      chatOpen: false,
    });
  }

  closeMaps = () => {
    this.setState({
      mapsOpen: false,
    });
  }

  closeChat = () => {
    this.setState({
      chatOpen: false,
    });
  }

  lockBody = () => {
    this.setState({
      lockBody: true,
    });
  }

  unlockBody = () => {
    this.setState({
      lockBody: false,
    });
  }


  render() {
    return (
      <main className={Classnames("contact", { ['open']: this.state.isOpen })}>
        {/* Details */}
        <div className="contact-right">
          <Details
            telephone={telephone}
            mailGeneral={general}
            mailRecruitment={recruitment}
            mailSales={sales}

            closeContact={this.closeContact}
            toggleMaps={this.toggleMaps}

            closeMaps={this.closeMaps}
            closeChat={this.closeChat}

            unlockBody={this.unlockBody}
          />
        </div>

        {/* Map, Chat */}
        <div className="contact-left">
          <Map
            mapsOpen={this.state.mapsOpen}
            mapHeight={"100vh"}
            mapWidth={"100vw"} />
          <Chat chatOpen={this.state.isOpen}/>
        </div>

        {/* Background - Click to Close Modal */}
        <div className="contact-bg"></div>
      </main>
    )
  }
}

export default ContactModal




