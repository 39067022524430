module.exports = {
  "tailor_made_perfect_fit": "Tailor Made Perfect Fit",
  "business_solutions": "Business Solutions",
  "small_business":"Small Business",
  "online":"Online",
  "retail_and":"Retail and",
  "group_collections":"Group Collections &",
  "custom_made":"Custom Made",
  "accounting":"Accounting",
  "accounting_para":"Manage suppliers, customers, inventory, expenses, cash flow and staff payroll amongst other business activities",
  "distribution":"Distribution",
  "field_sales":"Field Sales",
  "ecommerce":"ECommerce",
  "online_distribution":"Online Distribution",
  "retail_and_field_sales":"Retail & Field Sales",
  "custom_solutions":"Custom Solutions",
  "swift":"Swift",
  "solutions":"Solutions",
  "our_suite_of_solutions_para":"Our suite of Accounting, Sales Force, Trade Automation, Club Management, Logistics Management, Point of Sales and eCommerce solution help businesses of different kinds and sizes  manage their operations effortlessly.",
  "get_social": "Get Social",
  "hiring_now": "Hiring Now",
  "suitable":"Suitable",
  "all":"All",
  "history":"History",
  "mission_and_vision_para":"mission and vision for High Tech Synergy conceived",
  "company_was_incorporated":"company was incorporated",
  "version_1_of_flagship_software_para":"version 1 of flagship software, HTS Financials launched",
  "flagship_application_deployed_para":"flagship application deployed for various industry leaders in oil and gas, gaming and entertainment, agriculture and telecommunications sector",
  "version_2_of__para":"version 2 of HTS Financials launched",
  "version_2_deployed_para":"version 2 deployed for more telecoms operators in Nigeria, Ghana and Benin",
  "version_3_of__para":"version 3 of HTS Financials launched",
  "diversification_into_more_industries_para":"diversification into more industries and inclusion of b2c to b2b business model",
  "2020_applications_para":"Swift Accounting, Swift Logistics, Swift POS, Swift Biometrics, eDues and several other b2c applications launched",
  "for_every_business":"for every Business",
  "every_business_needs_para":"Every business needs the unfair advantage that our accounting solution provides. Built with manufacturing, trade and distribution, services, and so many more business categories in mind and is easily configurable to provide the tools needed with just a few clicks to give value from the get-go!",
  "retail": "Retail",
  "managed": "Managed",
  "services": "Services",
  "features_include": "Features Include",
  "feature_rich_yet_so_simple":"Feature rich, yet so simple",
  "feature_rich_yet_so_simple_para":"We’ve got you covered with several features to solve many of your business related problems",
  "intuitive_design":"Intuitive Design",
  "intuitive_design_para":"Every transaction is built as a cycle. You only need to initiate it and our app will intuitively guide you through the process of completion.",
  "highly_customizable":"Highly Customizable",
  "highly_customizable_para":"Fill out our getting started questionnaire and we will provide an interface with features tailored to suit your business needs",
  "mobile_first":"Mobile First",
  "mobile_first_para":"Our app is built to work end to end on your mobile device so you can work on the go",
  "robust_yet_affordable":"Robust, yet affordable",
  "robust_yet_affordable_para":"Packed with many modules and features at a very affordable rate",
  "standard_financial_reports":"Standard financial Reports",
  "standard_financial_reports_para":"Automate your financial reports such as Balance sheet, profit and loss, trial balance, cash flow statements, etc",
  "accountant_auditor_access":"Accountant / Auditor Access",
  "accountant_auditor_access_para":"Your accountants/auditors can manage your account from any location, making it easy to prepare your accounts as at when due",
  "back_to_case_studies":"Back to case studies",
  "featured":"featured",
  "how_we_made_impact":"See how we made impact with technology.",
  "services_we_provide": "Services we provide",
  "our_services_has_been_designed_para":"Our services have been designed to bring the results you expect exactly when and where you need them. Our services range from Technology Consulting and Business Analysis to Quality Assurance, Project Management, Application Development, Maintenance and Solution Integration.",
  "our_integrated_service_para":"Our integrated service lines have been shaped by more than a decade of delivering value to our customers, and are built on a deep understanding of business needs and market trends, along with the technology expertise and software industry best practices.",
  "accounting_distribution_field_sales_ecommerce_solutions": "We provide holistic solutions to our clients for improved overall business performance. Our dedication to offer efficient multi-disciplinary professional services tailored to suit our clients business needs has enabled us deploy and manage exemplary cutting-edge solutions across various industries.",
  "view_all_services": "View All Services",
  "innovative_solutions_used_in":"Innovative solutions used in",
  "case_studies":"Case Studies",
  "brilliant_case_studies":"Brilliant Case Studies",
  "satisfied_clients_para":"With some of our satisfied clients",
  "around_the_globe": "Around the globe",
  "trusted_technology": "Trusted technology in various markets",
  "we_ensure_you_para": "We ensure our solutions are configured to meet the specific needs of the industries they operate in, providing them with the tools and information about their business to make them stay ahead.",
  "lets_get_in_touch": "Let’s get in touch & find out how our technology will benefit your business today.",
  "contact_us": "Contact Us",
  "back_to_top": "Back to top",
  "solutions": "Solutions",
  "solutions__":"Solutions",
  "about": "About",
  "careers": "Careers",
  "contact": "Contact",
  "advanced": "Advanced",
  "platform": "Platform",
  "sales": "Sales",
  "experience": "Experience",
  "state_of_the_art_solutions_para": "We have been providing state-of-the-art solutions to various clients for over 13 years across the globe.",
  "customisation": "Customisation",
  "customisable_approach_para": "We ensure our solutions are a right fit to our clients’ business needs enabling innovation and corporate excellence.",
  "support": "Support",
  "we_provide_fanantical_support_para": "We provide fanatical support via various channels that ensure we re always within your reach.",
  "technical": "Technical",
  "support_desk": "Support Desk",
  "about_us": "About Us",
  "with_a_technical_reputation_para": "With a technical reputation established in the telecommunications sector developing software solutions for online distribution, trade force automation and field sales force platforms, High Tech Synergy is now a leading provider of solutions for growing businesses of all types.",
  "company": "Company",
  "ecommerce_solution_para":"Manage clubs and other groups keep track of member registration and dues, while keeping them engaged with key information and activities.",
  "our_ecommerce_solution_provides_para":"Our eCommerce solution is the right fit for every business that wants to automate its operations with zero downtime.",
  "available_opportunities": "Available Opportunities",
  "upcoming": "Upcoming",
  "get_in_touch": "Get In Touch",
  "telephone": "Telephone",
  "email": "Email",
  "find_us": "Find Us",
  "drop_us_a_message": "Drop us a message",
  "our_offices": "Our Offices",
  "our_office":"Our Office",
  "high_tech_synergy_is_regulated_and_licensed_para":"High Tech Synergy Limited is registered & licensed by the {CompanyRegNum}",
  "back_to_careers": "Back to careers",
  "apply_for_this_position": "Apply for this position",
  "your_application": "Your Application",
  "first_name": "First Name",
  "last_name": "Last Name",
  "cover_message": "Cover Message",
  "upload_your_cv": "Upload Your C.V.",
  "submit_application": "Submit Application",
  "find_out_more": "Find out more",
  "general": "General",
  "recruitment": "Recruitment",
  "back_to": "Back to Careers",
  "read_more": "Read More",
  "required_field": "Required Field",
  "submitting_application": "Submitting Application",
  "loading": "Loading...",
  "sent": "Sent",
  "thank_you_for_application": "Thank you for your application",
  "we_will_be_intouch": "We will be in touch shortly",
  "done": "Done",
  "fully": "Fully",
  "our": "Our",
  "retail_and": "Retail and",
  "nigeria":"Nigeria",
  "quick_links":"Quick Links",
  "the_latest": "The Latest",
  "it_s_robust":"It's Robust",
  "it_s_robust_para":"Packed with many features to help you keep track of dues, donations and payments made by your members as well as also engage them with news, events and chat groups",
  "a_plan_for_everyone":"A plan for everyone",
  "a_plan_for_everyone_para":"PDepending on the size of your club/association whether small, medium or large, we have a payment plan that just might suit your pocket",
  "instant_credit":"Instant Credit",
  "instant_credit_para":"No need for t+1 settlement and headaches of bank reconciliation. Payments made by members using their e-wallet reflect instantly",
  "donations":"Donations",
  "donations_para":"Apart from their preset dues, you can also receive instant donations from your members for various projects or activities",
  "news_events":"News & Events",
  "news_events_para":"Engage your members with the latest news and events happening at your club/association",
  "intuitive_design":"Intuitive Design",
  "intuitive_design_para":"It's user friendly and so easy to use. We are constantly Improving.",
  "chat_groups":"Chat Groups",
  "chat_groups_para":"Configure private and public chat rooms for your members. All members are automatically added to public chat groups while private chat groups are strictly based on invitation",
  "reports":"Reports",
  "reports_para":"View several reports such as list of members, debtors, dues, payments, etc",

}
