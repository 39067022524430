import React from 'react'

const Address = ({ firstline, secondline, city, postcode, country, email, telephone }) => (
    <ul>
        <li>{firstline}</li>
        <li>{secondline}</li>
        <li>{city}</li>
        <li>{postcode}</li>
        <li>{country}</li>
    </ul>
)

export default Address



