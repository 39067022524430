import React from 'react';

const icons = {
  'pin': 'M8,0C4.1,0,1,3.1,1,7c0,1.9,0.7,3.7,2.1,5c0.1,0.1,4.1,3.7,4.2,3.8c0.4,0.3,1,0.3,1.3,0 c0.1-0.1,4.2-3.7,4.2-3.8c1.4-1.3,2.1-3.1,2.1-5C15,3.1,11.9,0,8,0z M8,9C6.9,9,6,8.1,6,7s0.9-2,2-2c1.1,0,2,0.9,2,2S9.1,9,8,9z',
  'chat': 'M8,0C3.6,0,0,3.1,0,7c0,3.9,3.6,7,8,7c0.4,0,0.8,0,1.1-0.1L14,16v-4.4c1.2-1.2,2-2.8,2-4.6 C16,3.1,12.4,0,8,0z',
};

export default props => (
    <i>
        <svg viewBox="0 0 16 16">
            <path d={icons[props.icon]}></path>
        </svg>
    </i>
);



