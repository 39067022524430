import React from 'react'
// import { Link } from '../../../i18n'
import { Link } from "gatsby"
import Logoen from '../../../images/logo-en.svg'


const Logo = ({alt, pathContext}) => (
    <Link to="/" className="header-logo"> 
            <img src={ Logoen } alt="HTS Logo" className="logo-std"/>
    </Link>
);


export default Logo






