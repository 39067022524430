import React from 'react'
import Icon from '../icons/standard-icon'
import Classnames from 'classnames'
import { withPrefix } from 'gatsby-link'
import root from 'window-or-global'

class LangToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            closed: true,
        };
    }

    setLanguage(code) {
        if (typeof window !== 'undefined') {
            const {originalPath} = this.props.pathContext;
            const newUrl = withPrefix(`/${code}${originalPath}`)
            window.localStorage.setItem('language', code)
            window.location.replace(newUrl)
        }
    }

    render() {
        // Skip build, Browsers only
        // let locale = (typeof window !== 'undefined') ? this.props.pathContext.locale : 'en';
        let locale =  "en";
        return (<></>);
        return (
            <div className="header-lang no-print">
                <button className={Classnames("header-lang__btn",{'open': this.state.open})} onClick={() => {this.setState({open: !this.state.open})}} onBlur={() => {this.setState({open: !this.state.closed})}}>
                    <em className="noline">{locale.toUpperCase()}</em>
                    <Icon icon="down"/>
                </button>
        
                <div className={Classnames("header-lang__menu",{'open': this.state.open})}>
                    <ul>
                        <li className={ locale === 'en' ? 'active' : ''}>
                            <a onClick={() => { this.setLanguage('en')} }>
                                <em className="noline light">
                                    EN
                                </em>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}


export default LangToggle



