import axios from "axios";
import globalStore from "./globalStore";
import browserLang from "browser-lang";
import { languages } from "../i18n/index";

/*
    Detect language of browser
*/

const getLocale = apiUrl => {
	const langKeys = languages.map(language => language.value);

	// Skip build, Browsers only
	if (typeof window !== "undefined") {
		const detected =
			window.localStorage.getItem("language") ||
			browserLang({
				languages: langKeys,
				fallback: "en"
			});
		return `${apiUrl}?lang=${detected}`;
	} else {
		return `${apiUrl}?lang=en`;
	}
};

const getURL = endpoint => {
	const root = window.location.href.includes("staging")
		? "http://staging.hts.com.ng"
		: "https://hts.com.ng";
	return getLocale(`${root}/api/${endpoint}`);
};
/*
    Create local model from responses 
    instead of hitting API every time
*/
const get = (endpoint, callback) => {
	// detect if theres a window before doing network calls.
	// breaks build command otherwise...

	if (typeof document !== "undefined") {
		if (globalStore[endpoint] && globalStore[endpoint].length > 0) {
			return callback(globalStore[endpoint]);
		}
		axios.get(getURL(endpoint)).then(res => {
			const { data } = res;
			globalStore[endpoint] = data;
			return callback(data);
		});
	}
};

export default {getLocale,getURL, get};







// ./src/utils/network.js