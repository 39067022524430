import React from 'react';
import Link from 'gatsby-link';


const ContactInfo = ({ email, telephone }) => (
    <ul>
      {email && (
        <li>
            <a href={`mailto: ${email}`}><span>E.&nbsp;</span><main>{email}</main></a>
        </li>
      )}
      {telephone && (
        <li>
            <a href={`tel: ${telephone}`}><span>T.&nbsp;</span><main>{telephone}</main></a>
        </li>
      )}
    </ul>
)

export default ContactInfo



