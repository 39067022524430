import React from 'react';

const icons = {
  'down': 'M16.002 28c0.652 0 1.264-0.318 1.638-0.854l14-20c0.428-0.61 0.476-1.408 0.136-2.070-0.346-0.662-1.028-1.076-1.774-1.076h-28.004c-0.746 0-1.428 0.414-1.774 1.076-0.34 0.662-0.292 1.46 0.136 2.070l14 20c0.374 0.536 0.986 0.854 1.638 0.854 0.002 0 0.002 0 0.004 0-0.002 0-0.002 0 0 0z',
  'career': 'M14 18v0c-4.4 0-8-3.6-8-8v-2c0-4.4 3.6-8 8-8v0c4.4 0 8 3.6 8 8v2c0 4.4-3.6 8-8 8z M10 22c-5.6 0-10 4.4-10 10h16v-10h-6z M28 20h-4v4h-4v4h4v4h4v-4h4v-4h-4z',
  'facebook': 'M29.333 0h-26.667c-1.473 0-2.667 1.193-2.667 2.667v26.667c0 1.473 1.193 2.667 2.667 2.667h14.667v-12h-4v-5.333h4v-3.449c0-4.133 2.524-6.384 6.212-6.384 1.767 0 3.284 0.132 3.727 0.191v4.32l-2.557 0.001c-2.005 0-2.393 0.953-2.393 2.351v2.971h5.92l-1.333 5.333h-4.587v12h7.012c1.473 0 2.667-1.193 2.667-2.667v-26.667c0-1.473-1.193-2.667-2.667-2.667z',
  'twitter': 'M32 6.133c-1.2 0.533-2.4 0.933-3.733 1.067 1.333-0.8 2.4-2.133 2.933-3.6-1.333 0.8-2.667 1.333-4.133 1.6-1.2-1.333-2.933-2.133-4.8-2.133-3.6 0-6.533 2.933-6.533 6.533 0 0.533 0 1.067 0.133 1.467-5.6-0.267-10.4-2.933-13.6-6.933-0.667 1.067-0.933 2.133-0.933 3.333 0 2.267 1.2 4.267 2.933 5.467-1.067 0-2.133-0.267-2.933-0.8 0 0 0 0 0 0.133 0 3.2 2.267 5.867 5.2 6.4-0.533 0.133-1.067 0.267-1.733 0.267-0.4 0-0.8 0-1.2-0.133 0.8 2.667 3.2 4.533 6.133 4.533-2.267 1.733-5.067 2.8-8.133 2.8-0.533 0-1.067 0-1.6-0.133 2.933 1.867 6.4 2.933 10 2.933 12.133 0 18.667-10 18.667-18.667 0-0.267 0-0.533 0-0.8 1.333-0.933 2.4-2.133 3.333-3.333z',
  'instagram': 'M16 2.883c4.272 0 4.779 0.016 6.465 0.093 1.821 0.083 3.511 0.448 4.811 1.748s1.665 2.989 1.748 4.811c0.077 1.687 0.093 2.193 0.093 6.465s-0.016 4.779-0.093 6.465c-0.083 1.821-0.448 3.511-1.748 4.811s-2.989 1.665-4.811 1.748c-1.687 0.077-2.193 0.093-6.465 0.093s-4.779-0.016-6.465-0.093c-1.821-0.083-3.511-0.448-4.811-1.748s-1.665-2.989-1.748-4.811c-0.077-1.687-0.093-2.193-0.093-6.465s0.016-4.779 0.093-6.465c0.083-1.821 0.448-3.511 1.748-4.811s2.989-1.665 4.811-1.748c1.687-0.077 2.193-0.093 6.465-0.093zM16 0c-4.345 0-4.891 0.019-6.597 0.096-2.6 0.119-4.884 0.756-6.717 2.589s-2.471 4.117-2.589 6.717c-0.077 1.707-0.096 2.252-0.096 6.597s0.019 4.891 0.096 6.597c0.119 2.6 0.756 4.884 2.589 6.717s4.117 2.471 6.717 2.589c1.707 0.077 2.252 0.096 6.597 0.096s4.891-0.019 6.597-0.096c2.6-0.119 4.884-0.756 6.717-2.589s2.471-4.117 2.589-6.717c0.077-1.707 0.096-2.252 0.096-6.597s-0.019-4.891-0.096-6.597c-0.119-2.6-0.756-4.884-2.589-6.717s-4.117-2.471-6.717-2.589c-1.707-0.077-2.252-0.096-6.597-0.096v0z M16 7.784c-4.537 0-8.216 3.679-8.216 8.216s3.679 8.216 8.216 8.216 8.216-3.679 8.216-8.216-3.679-8.216-8.216-8.216zM16 21.333c-2.945 0-5.333-2.388-5.333-5.333s2.388-5.333 5.333-5.333 5.333 2.388 5.333 5.333-2.388 5.333-5.333 5.333z M26.461 7.459c0 1.060-0.86 1.92-1.92 1.92s-1.92-0.86-1.92-1.92c0-1.060 0.86-1.92 1.92-1.92s1.92 0.86 1.92 1.92z',
  'linkedin': 'M30.667 0h-29.333c-0.8 0-1.333 0.533-1.333 1.333v29.333c0 0.8 0.533 1.333 1.333 1.333h29.333c0.8 0 1.333-0.533 1.333-1.333v-29.333c0-0.8-0.533-1.333-1.333-1.333zM9.467 27.333h-4.667v-15.333h4.8v15.333zM7.067 9.867c-1.467 0-2.8-1.2-2.8-2.8 0-1.467 1.2-2.8 2.8-2.8 1.467 0 2.8 1.2 2.8 2.8s-1.2 2.8-2.8 2.8zM27.333 27.333h-4.8v-7.467c0-1.733 0-4-2.4-4-2.533 0-2.8 1.867-2.8 3.867v7.6h-4.8v-15.333h4.533v2.133c0.667-1.2 2.133-2.4 4.533-2.4 4.8 0 5.733 3.2 5.733 7.333v8.267z',
  'chevron-left': 'M20 26.8l-10.8-10.8 10.8-10.8 2.8 2.8-8 8 8 8z',
  'chevron-right': 'M12 26.8l-2.8-2.8 8-8-8-8 2.8-2.8 10.8 10.8z',
  'arrow-up': 'M14 14v16c0 1.2 0.8 2 2 2v0c1.2 0 2-0.8 2-2v-16h10l-12-14-12 14h10z',
};

export default props => (
    <i>
        <svg viewBox="0 0 32 32">
            <path d={icons[props.icon]}></path>
        </svg>
    </i>
);



