import React from 'react';
import moment from 'moment';

const Copyright = () => (
    <em className="noline grey" data-text="">&copy; High Tech Synergy {moment().format('YYYY')}</em>
)

export default Copyright




