module.exports = {
  title: 'Gatsby 한국어',
  hello: '여러분 안녕하세요',
  welcome: '개츠비 사이트에 오신 것을 환영합니다',
  gopage2: '페이지 2로 이동',
  build: '이제 새로운 것을 만들어 보세요.',
  welcome2: '페이지 2 입니다',
  goback: '홈페이지로 가기',
}




// ./src/i18n/locales/ko.js