import React from "react";
import Icon from "../icons/standard-icon";
import { runInThisContext } from "vm";
import { FormattedMessage } from "react-intl";

class BacktoTop extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			scrollPos: 0
		};

		this.scroller = null;
	}

	scrollStep() {
		if (window.pageYOffset === 0) {
			clearInterval(this.scroller);
		}
		window.scroll(0, window.pageYOffset - 35);
	}

	scrollToTop = () => {
		this.scroller = setInterval(this.scrollStep.bind(this), 0);
	};

	render() {
		return (
			<button
				className="footer-bot__item--btt"
				onScroll={this.updateScroll}
				onClick={this.scrollToTop}
			>
				<em className="grey">
					<FormattedMessage id="back_to_top" tagName="span" />
					<Icon icon="arrow-up" />
				</em>
			</button>
		);
	}
}

export default BacktoTop;




