module.exports = {
  title: 'Gatsby Deutsch',
  hello: 'hallooooo Leute',
  welcome: 'Willkommen auf Ihrer neuen Gatsby-Site.',
  gopage2: 'Gehen Sie zu Seite 2',
  build: 'Jetzt mach etwas Großartiges.',
  welcome2: 'Willkommen auf Seite 2',
  goback: 'Gehe zurück zur Startseite',
}




// ./src/i18n/locales/de.js